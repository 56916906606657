/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  SiteVariablesFragment,
  TermsPageFragment,
} from "src/components/QueryFragments"
import "src/assets/scss/material-kit-react.scss"

import TermsPageView from "../views/TermsPage"

const TermsPage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPageTermsConditions,
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPageTermsConditions {
          ...TermsPageFragment
        }
      }
    `
  )
  return (
    <TermsPageView
      siteVariables={contentfulWebsiteGlobalVariables}
      pageContent={contentfulPageTermsConditions}
      location={location}
    />
  )
}

export default TermsPage
